import {
  faCopy,
  faTimes
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, useCallback } from 'react'

import { useAppSelector } from '../../store/types'
import { DropdownState } from '../../store/upload/types'
import { getAllowOrderEntry } from '../../store/webSettings/selectors'
import Button from '../Button/Button'
import ButtonWithFeedback from '../ButtonWithFeedback/ButtonWithFeedback'
import styles from './uploadDropDownMenu.module.scss'

interface Props {
  clearAllRows: () => void
  errorMessage: string
  dropdownState: DropdownState
  toggleNewWatchlist: () => void
  cancel: () => void
  copyData: () => void
  title: string
  isIceberg?: boolean
  setIsIceberg?: (value: boolean) => void
  removeInvalid?: () => void
}

const switchContainerClass = 'pretty p-switch'

const UploadMenuHeader: React.FC<Props> = ({
  clearAllRows,
  errorMessage,
  dropdownState,
  toggleNewWatchlist,
  cancel,
  copyData,
  title,
  isIceberg,
  setIsIceberg,
  removeInvalid
}) => {
  const allowOrderEntry = useAppSelector(getAllowOrderEntry)
  const handleIcebergChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (setIsIceberg) {
        setIsIceberg(e.currentTarget.checked)
      }
    },
    [setIsIceberg]
  )
  return (
    <div className={styles.uploadMenuHeader}>
      <div className={styles.uploadMenuHeaderLeft}>
        {dropdownState === 'upload' && (
          <span>
            <Button onClick={toggleNewWatchlist}>Create new watchlist</Button>
          </span>
        )}
        {dropdownState === 'newWatchlist' && (
          <span>Enter Information Below</span>
        )}
        {dropdownState === 'invalidUpload' && (
          <span className={styles.uploadMenuHeaderInvalid}>
            Unable to Submit Orders
          </span>
        )}
      </div>
      {errorMessage !== '' && (
        <>
          <div className={styles.errorMessage} data-testid="manualUploadError">
            {errorMessage}
          </div>
          {!!removeInvalid && (
            <button type="button" onClick={removeInvalid}>
              Remove invalid rows
            </button>
          )}
        </>
      )}
      <div className={styles.uploadMenuHeaderRight}>
        {dropdownState === 'invalidUpload' ? (
          <span className={styles.iconSpanAsButton}>
            <FontAwesomeIcon icon={faTimes} onClick={cancel} />
          </span>
        ) : (
          <>
            {!!setIsIceberg && allowOrderEntry && (
              <div className={switchContainerClass}>
                <input
                  type="checkbox"
                  name="iceberg"
                  id="upload-iceberg-switch"
                  data-testid="upload-iceberg-switch"
                  checked={isIceberg}
                  onChange={handleIcebergChange}
                />
                <div className="state p-primary">
                  <label htmlFor="upload-iceberg-switch">Iceberg</label>
                </div>
              </div>
            )}
            <button onClick={clearAllRows}>Clear All</button>
            <ButtonWithFeedback
              contentUpdate="COPIED"
              onClick={copyData}
              timerToReturnToFirstState={3000}
              title={title}
            >
              <FontAwesomeIcon icon={faCopy} />
            </ButtonWithFeedback>
          </>
        )}
      </div>
    </div>
  )
}

export default UploadMenuHeader
