import { createReduxEnhancer } from '@sentry/react'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { combineEpics, createEpicMiddleware } from 'redux-observable'

import activityLogEpic from './activityLog/epic'

import activityTradeBlotterEpic from './activityTradeBlotter/epic'

import activityTickerEpic from './admin/activityTicker/epic'

import tradeBlotterEpic from './admin/adminTradeBlotter/epic'

import aggressAttemptEpic from './admin/aggressAttempt/epic'

import bbmIsinEpic from './admin/bbmisin/epic'
import externalLiquidityStatsEpic from './admin/externalLiquidityStats/epic'

import externalOrderStatsEpic from './admin/externalOrderStats/epic'

import historicalUserDataEpic from './admin/historicalUserData/epic'

import managementReportsEpic from './admin/managementReports/epic'
import queryEpic from './admin/query/epic'

import queueStatsEpic from './admin/queueStats/epic'

import restrictedTradesEpic from './admin/restrictedTrades/epic'

import systemParamEpic from './admin/sysparam/epic'

import tiersEpic from './admin/tiers/epic'
import userDashboardEpic from './admin/userDashboard/epic'
import venueEpic from './admin/venues/epic'
import alertFiltersEpic from './alertFilter/epic'
import alertsEpic from './alerts/epic'

import reducer from './appReducer'
import authEpic from './auth/epic'

import benchmarkDataEpic from './benchmarkData/epic'
import booksEpic from './books/epic'
import checkedOrdersEpic from './checkedOrders/epic'

import customersEpic from './customers/epic'
import depthOfMarketEpic from './depthOfMarket/epic'
import gridEpic from './grid/epic'
import heartbeatEpic from './heartbeat/epic'
import lastLookEpic from './lastLook/epic'
import logEpic from './log/epic'
import marketEpic from './market/epic'
import orderEpic from './order/epic'
import passiveOrdersEpic from './passiveOrders/epic'
import searchSecuritiesEpic from './searchSecurities/epic'
import securitiesEpic from './securities/epic'
import settingsEpic from './settings/epic'
import stagedOrdersEpic from './stagedOrders/epic'
import { State, StoreAction } from './types'
import uploadOrdersEpic from './upload/epic'

import userEditorDataEpic from './userEditorData/epic'
import userPreferencesEpic from './userPreferences/epic'

import usersEpic from './users/epic'
import watchListEpic from './watchList/epic'
import webSettingsEpic from './webSettings/epic'
import windowsEpic from './windows/epic'
import wsEpic from './ws/epic'

const rootEpic = combineEpics(
  authEpic,
  activityLogEpic,
  activityTradeBlotterEpic,
  aggressAttemptEpic,
  benchmarkDataEpic,
  depthOfMarketEpic,
  securitiesEpic,
  windowsEpic,
  orderEpic,
  wsEpic,
  lastLookEpic,
  watchListEpic,
  checkedOrdersEpic,
  stagedOrdersEpic,
  searchSecuritiesEpic,
  userPreferencesEpic,
  settingsEpic,
  booksEpic,
  alertsEpic,
  uploadOrdersEpic,
  marketEpic,
  logEpic,
  heartbeatEpic,
  webSettingsEpic,
  usersEpic,
  customersEpic,
  tiersEpic,
  historicalUserDataEpic,
  externalOrderStatsEpic,
  queueStatsEpic,
  queryEpic,
  gridEpic,
  alertFiltersEpic,
  systemParamEpic,
  bbmIsinEpic,
  managementReportsEpic,
  userEditorDataEpic,
  tradeBlotterEpic,
  activityTickerEpic,
  userDashboardEpic,
  restrictedTradesEpic,
  passiveOrdersEpic,
  venueEpic,
  externalLiquidityStatsEpic
)

const epicMiddleware = createEpicMiddleware()
const sentryReduxEnhancer = createReduxEnhancer({})

const getStore = () => {
  const store = createStore<State, StoreAction, unknown, unknown>(
    reducer,
    composeWithDevTools(applyMiddleware(epicMiddleware), sentryReduxEnhancer)
  )

  epicMiddleware.run(rootEpic)

  return store
}

export default getStore
