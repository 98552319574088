import { faTriangleExclamation } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useTimeoutFn } from 'react-use'
import styles from '../../containers/DepthOfMarket/depthPopout.module.scss'
import { domLimitExceeded } from '../../store/depthOfMarket/actions'
import { getMaxDOMs } from '../../store/depthOfMarket/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'

const DOMLimitMessage = () => {
  const dispatch = useAppDispatch()
  const maxDOMs = useAppSelector(getMaxDOMs)

  const [clear] = useTimeoutFn(() => {
    dispatch(domLimitExceeded(false))
  }, 5000)

  useEffect(() => {
    return () => {
      clear()
    }
  }, [clear])

  return (
    <div className={styles.domLimitExceeded}>
      <FontAwesomeIcon icon={faTriangleExclamation} />
      <p>
        Maximum amount of depth windows ({maxDOMs}) have been opened. Please
        close some windows before opening more.
      </p>
    </div>
  )
}

export default DOMLimitMessage
