import { createSelector } from 'reselect'
import { namespace } from '.'
import { getMarketClosed } from '../market/selectors'
import { Order, OrderType } from '../order/types'
import { Security } from '../securities/reducer'
import {
  getSecurityOrderDataById,
  getSecurityStaticDataById
} from '../securities/selectors'
import { initialDOM, State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const getDepthOfMarket = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.gridDOMs[gridIndex] || initialDOM
)

export const getPopoutSecurityIds = createSelector(
  [getState],
  (state) => state.popoutSecurityIds
)

export const getDOMLimitExceeded = createSelector(
  [getState],
  (state) => state.domLimitExceeded
)

export const getMaxDOMs = createSelector([getState], (state) => state.maxDOMs)

const getAllDOMOrders = createSelector([getState], (state) =>
  Object.values(state.gridDOMs).reduce(
    (acc, dom) => [...acc, ...(dom?.orders || [])],
    []
  )
)

export const getDepthOfMarketOrder = createSelector(
  [getAllDOMOrders, getSecurityOrderDataById],
  (orders, getSecurity) =>
    (orderId: Order['id'], securityId: Security['id']) => {
      const security = getSecurity(securityId)
      if (security?.bestBid?.id === orderId) {
        return security.bestBid
      }
      if (security?.bestOffer?.id === orderId) {
        return security.bestOffer
      }
      return orders.find((order) => order.id === orderId)
    }
)

export const getDepthOfMarketOrderByInfo = createSelector(
  [getAllDOMOrders, getSecurityOrderDataById],
  (orders, getSecurity) =>
    (
      securityId: Security['id'],
      orderType: OrderType,
      price: number,
      size: number
    ) => {
      const orderMatch = (order: Order) =>
        order.securityId === securityId &&
        order.type === orderType &&
        order.price === price &&
        order.size === size &&
        order.canAggress
      const security = getSecurity(securityId)
      if (security?.bestBid && orderMatch(security.bestBid)) {
        return security.bestBid
      }
      if (security?.bestOffer && orderMatch(security.bestOffer)) {
        return security.bestOffer
      }
      return orders.find(orderMatch)
    }
)

export const getMarketForSecurity = createSelector(
  [getState, getMarketClosed],
  (state, marketIsClosed) =>
    (gridIndex: number, securityId: Security['id'], type: OrderType) =>
      state.gridDOMs[gridIndex]?.orders
        .filter(
          (order) => order.securityId === securityId && order.type === type
        )
        .map((order) =>
          marketIsClosed ? { ...order, canAggress: false } : order
        ) ?? []
)

export const getDepthSecurities = createSelector(
  [getPopoutSecurityIds, getSecurityStaticDataById],
  (popoutIds, getSecurityData) => {
    return popoutIds
      .map((id) => getSecurityData(id))
      .filter((security) => !!security?.id)
  }
)
