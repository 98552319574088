import cx from 'classnames'
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo
} from 'react'
import {
  newSubscription,
  removeSubscription
} from '../../../store/depthOfMarket/actions'
import { getMarketForSecurity } from '../../../store/depthOfMarket/selectors'
import { cancelOrder } from '../../../store/order/actions'
import { isOrderMine } from '../../../store/order/selectors'
import { Order } from '../../../store/order/types'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { getQuoteReliability } from '../../../store/securities/selectors'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import { openAggressorWindow } from '../../../store/windows/actions'
import { OrderDisplay } from '../helpers'
import BuyOrSellDepth from './BuyOrSellDepth'

import styles from './DepthOfMarket.module.scss'

interface Props {
  gridIndex: number
  security: SecurityStaticData
  footer?: ReactNode
  isPopout?: boolean
  className?: string
  extraColumns?: OrderDisplay[]
}

const DepthOfMarket = ({
  security,
  children,
  className,
  isPopout,
  gridIndex,
  footer,
  extraColumns
}: PropsWithChildren<Props>) => {
  const dispatch = useAppDispatch()
  const orderIsMine = useAppSelector(isOrderMine)
  const quoteReliability = useAppSelector(getQuoteReliability)(gridIndex)

  // TODO: custom equality fn for more efficient rendering
  const sellRows = useAppSelector((state) =>
    getMarketForSecurity(state)(gridIndex, security.id, 'sell')
  )
  const buyRows = useAppSelector((state) =>
    getMarketForSecurity(state)(gridIndex, security.id, 'buy')
  )

  const aggressHandler = useCallback(
    (order: Order) => {
      if (orderIsMine(order.id)) {
        dispatch(cancelOrder(order.id))
      } else {
        dispatch(openAggressorWindow(order.id, security.id))
      }
    },
    [orderIsMine, sellRows, buyRows]
  )

  const myOrders = useMemo(() => {
    return [...sellRows, ...buyRows]
      .filter((order) => orderIsMine(order.id))
      .map((order) => order.id)
  }, [orderIsMine, sellRows, buyRows])

  useEffect(() => {
    dispatch(
      newSubscription(gridIndex, security.id, quoteReliability, !!isPopout)
    )
    return () => {
      dispatch(removeSubscription(gridIndex, security.id, !!isPopout))
    }
  }, [])

  return (
    <div className={className}>
      {children}
      <div className={styles.depthTable}>
        <BuyOrSellDepth
          type="buy"
          rows={buyRows}
          aggressCallback={aggressHandler}
          securityId={security.id}
          currency={security.currency}
          className={cx(styles.bids, styles.depth)}
          myOrders={myOrders}
          extraColumns={extraColumns}
        />
        <BuyOrSellDepth
          type="sell"
          rows={sellRows}
          aggressCallback={aggressHandler}
          securityId={security.id}
          currency={security.currency}
          className={cx(styles.offers, styles.depth)}
          myOrders={myOrders}
          extraColumns={extraColumns}
        />
      </div>
      {footer}
    </div>
  )
}

export default DepthOfMarket
